import axios from 'axios';
import authHeader from "@/service/AuthHeader";
import getEnvironment from "@/service/Environment";

const API_URL = getEnvironment().API_LOGGED_IN_URL + 'content';

export default class ContentService {
    getAllGeneralTerms() {
        return axios.get(API_URL + '/list-general-terms', { headers: authHeader() });
    }

    getAllPrivacyStatements() {
        return axios.get(API_URL + '/list-privacy-statements', { headers: authHeader() });
    }

    createGeneralTerm(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + '/save-general-term', data, { headers:  header });
    }

    createPrivacyStatement(data) {
        const header = Object.assign({'Content-Type': 'application/json'}, authHeader());
        return axios.post(API_URL + '/save-privacy-statement', data, { headers:  header });
    }

    deleteGeneralTerm(id) {
        return axios.delete(API_URL + `/delete-general-term/${id}`, { headers: authHeader() });
    }

    deletePrivacyStatement(id) {
        return axios.delete(API_URL + `/delete-privacy-statement/${id}`, { headers: authHeader() });
    }
}
